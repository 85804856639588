<template>
  <div class="home">
    <Modal v-if="showTeilnahme" v-on:close-modal="showTeilnahme = false">
      <TeilnahmebedingungenWB />
    </Modal>
    <div class="welcome container">
      <div class="title">
        <h1>Literatur-Wettbewerb 2024</h1>
        <h2>„Über Grenzen“</h2>
        <h3>Einsendungen waren vom 01.03.2024 - 10.05.2024 möglich.</h3>
      </div>

      <p>
        Er ist Teilnahme und Teilhabe zugleich! Unter dem Motto
        <strong>„Über Grenzen“</strong>
        laden wir Schreibende aus allen Generationen ein, sich mit eigenen
        Texten zu beteiligen. Geht es doch in der Poesie - in der Kunst des
        Wortes - immer wieder darum, das Unsagbare sagbar zu machen.
      </p>
      <br />

      <div class="center">
        <div>Nehmen Sie hier am Wettbewerb teil:
      <router-link v-if="!user" class="link user" :to="{ name: 'WB_WerkCreate' }"
              > <button>Einreichen</button> 
            </router-link>
          </div>
            
          <div>Stöbern Sie in den Einreichungen:
      <router-link v-if="!user" class="link user" :to="{ name: 'WB_Werke' }"
              > <button>Wettbewerbsbeiträge</button> 
            </router-link>

          </div>
          </div><h3>Ermittlung der Preisträger:innen</h3>

      <p>
        <strong>Der Publikumspreis:</strong>
        Die Publikumsjury sind alle Leser:innen, die eine Bewertung zu den
        eingereichten Beiträgen im Wettbewerbsportal abgeben. Das am
        besten abgestimmte Werk wird am Ende zum Publikumspreisträger gekürt.
      </p>

      <p>
        <strong>Der Jurypreis:</strong> Eine hochkarätig besetzte Fachjury mit
        prominenten Lesenden bestimmt in einem intensiven Auswahlverfahren über
        die Vergabe des Jury-Preises.
      </p>
          Die Jury-Preisträger:innen des Hildesheimer Literaturwettbewerbs 2024 sind: 
<ul>
       <li>Hauptpreis Prosa:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/4383"> Anna Arning - Black Table</a></li> 
       <li>Hauptpreis Prosa International:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/4662"> Kristin Vardi - Dramaturgie des Aufschubs</a></li> 
       <li>Hauptpreis Lyrik:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/3790"> Phillip Létranger - wehrhaftigkeit</a></li> 
       <li>Hauptpreis Lyrik:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/3927"> Stefanie Bucifal - Guerilla-Blume</a></li> 
       <li>Hauptpreis Lyrik:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/4413"> Melissa Tara Nielsen - Weiße Flagge</a></li> 
       <li>Hauptpreis Lyrik International:<a href="https://www.literatur-apotheke.de/wettbewerb/praeparat/4441"> Karl Johann Müller - an deiner Seite</a></li> 



</ul>
    Wir gratulieren allen Gewinner:innen vom ganzen Herzen!
      <br />
      <h3>Prämierung und Veröffentlichung</h3>
      <p>
        Was gibt es für eine Preisträgerin oder einen Preisträger Schöneres, als
        publiziert zu werden! Dies geschieht hier auf vierfache Weise:
      </p>
      <ul>
        <li>in einem feierlichen Festakt in der Dombibliothek zu Hildesheim</li>
        <li>über Plakate und Preisträgerbroschüren im öffentlichen Nahverkehr auf
          allen Buslinien in Hildesheim; bei täglich 50.000 Fahrgästen ein
          Riesenpublikum.
        </li>
        <li>durch Veröffentlichung auf einer Bestenliste im Internet</li>
        <li>durch Aufnahme in den Bestand der Literatur-Apotheke</li>
      </ul>
      <br />
      <p>
        Neben der klassischen Suche nach den schönsten Beiträgen, den größten
        Nuggets im Fluss der Poesie, interessiert uns auch die Motivation. Was
        treibt die Menschen zum Schreiben? Worin liegt die Bedeutung des
        Geschriebenen in dieser Zeit?
      </p>
      <p>
        Denn mit großer Freude sehen wir, wie viele Menschen im Schreiben einen
        Ausdruck suchen; darunter viele (noch) unbekannte Autoren und
        Autorinnen, die im Literaturbetrieb kaum keine Möglichkeit finden, sich
        Gehör zu verschaffen.
      </p>
      <p>
        Mit dem Wettbewerb wollen wir aber auch den unmittelbaren Austausch
        fördern, die abstrakte Rezensionskultur aus der babylonischen
        Gefangenschaft des Akademischen befreien und für neue Leser und
        Leserinnen attraktiv machen.
      </p>
    </div>

    <!--WerkPost v-if="user" :post="welcomeScreen" /-->

    <div class="blog-card-wrap container">
      <h2>Zuletzt eingereicht:</h2>
      <div class="container">
        <div class="recent-werke">
          <div v-for="werk in recentWerke" :key="werk.WerkId">
      
            <router-link class="link" :to="{ name: 'WB_WerkView', params: { werkid: werk.WerkId } }" :title="werk.Titel">
              <WerkSlot :werk="werk" />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!this.$store.getters['auth/isLoggedIn']" class="updates">
      <div class="container">
        <h2></h2>
        <router-link class="router-button" :to="{ name: 'Login' }">
          Einloggen/Registrieren
          <Arrow class="arrow arrow-light" />
        </router-link>
      </div>
    </div>
    <div class="text-center foerderer">
      <img
        src="../../assets/foerderer/las.png"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/sk.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/lkhi.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/lv.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/stadt.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/wh.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/svhi.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/nda.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/vgh.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <img
        src="../../assets/foerderer/mwk.jpg"
        class="img-fluid"
        alt="Responsive image"
      />
      <br />
    </div>
  </div>
</template>

<script>
//import WerkPost from "../../components/WerkPost";
//import WerkSlot from "../../components/WerkCard";
import Arrow from "../../assets/Icons/arrow-right-light.svg";
import Modal from "../../components/Modal.vue";
import werkeService from "../../services/werke.service";
import WerkSlot from "../../components/WerkCard.vue";
import TeilnahmebedingungenWB from "../../views/TeilnahmebedingungenWB.vue";

export default {
  name: "Home",
  components: { Arrow, Modal, TeilnahmebedingungenWB,WerkSlot },
  data() {
    return {
      showTeilnahme: false,
recentWerke:[]
    };
  },
  async created(){
    this.recentWerke = await werkeService.getWerkeFiltered(
      "limit=3&order=0&wb=1"
    );
  },
  computed: {

    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss">
.link {
  // text-decoration: underline;
}

.title {
  text-align: center;

  h1 {
    //padding: 30px;

    font-size: calc(14px + 1.5vw);
    font-weight: lighter;
  }

  h2 {
    //padding: 30px;

    font-size: calc(8px + 1.5vw);
    font-weight: bold;
  }

  h3 {
    //padding: 30px;

    font-size: calc(4px + 1.5vw);
    font-weight: bold;
  }
}

.welcome {
  padding: 5%;

  max-width: 1000px;

  @media (min-width: 0px) {
    text-align: justify;
    white-space: pre-wrap;
  }
}

.welcome h1,
h2,
h3,
h5 {
  text-align: center;
}

.blog-card-wrap {
  padding: 5%;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stamm {
  //width: 100%;
  padding: 20px;
  max-width: 600px;
}

.updates {
  .container {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;

    //align-items: center;
    @media (min-width: 800px) {
      padding: 15px 20px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;

      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
  }
}

.recent-werke {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  @media (min-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.foerderer {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  .img-fluid {
    width: calc(6% + 50px);
    padding: 20px;
    vertical-align: middle;
  }
}
</style>
